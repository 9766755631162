import React, { useState }  from "react"
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button } from 'components/common'



const GetForm = () => {
    
	const [serverState, setServerState] = useState({
		submitting: false,
		status: null
	});
	const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg }
		});
		if (ok) {
			form.reset();
		}
	};
	const handleOnSubmit = e => {
		e.preventDefault();
		const form = e.target;
		setServerState({ submitting: true });
		axios({
			method: "post",
			url: "https://getform.io/f/ba183565-08a4-415e-80c6-f6e7e4e14fe1",
			// eslint-disable-next-line no-undef
			data: new FormData(form)
		})
			// eslint-disable-next-line no-unused-vars
			.then(r => {
				handleServerResponse(true, "Thanks! - Team Endslate", form);
			})
			.catch(r => {
				handleServerResponse(false, r.response.data.error, form);
			});
	};
	return ( 
		<div>
			<div className="col-md-8 mt-5">
				<form onSubmit={handleOnSubmit}>
					<div className="form-group">
						<label htmlFor="exampleInputEmail1" required="required">Email Address</label>
						<input type="email" name="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
					</div> 
					<div className="form-group">
						<label htmlFor="exampleInputName">Name</label>
						<input type="text" name="name" className="form-control" id="exampleInputName" placeholder="Enter Your Name or Business Name" required="required" />
					</div>
					<div className="form-group">
						<label htmlFor="exampleFormControlSelect1">Please Select Consultation Service:</label>
						<select className="form-control" id="exampleFormControlSelect1" name="platform" required="required">
							<option>Progressive Websites</option>
							<option>eCommerce Development Services</option>
							<option>Paid Advertising & Acquisition</option>
							<option>Digital Marketing Management</option>
							<option>Conversion Rate Optimization</option>
							<option>Social Media Strategy & Management</option>
							<option>Branded Integrations & Influencer Marketing</option>

						</select>
					</div>
					<Button type="submit" className="btn btn-primary" disabled={serverState.submitting}>
                Submit
					</Button>
					{serverState.status && (
						<p className={!serverState.status.ok ? "errorMsg" : ""}>
							{serverState.status.msg}
						</p>
					)}
				</form>
			</div>
		</div>   
	);
};
  
export default GetForm;